var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aside" }, [
    _c(
      "div",
      {
        directives: [{ name: "scrollBar", rawName: "v-scrollBar" }],
        staticClass: "aside-main"
      },
      [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "menu-item",
            class: { active: _vm.$route.path.includes("/main") },
            on: {
              click: function($event) {
                return _vm.$_goto("/main")
              }
            }
          },
          [
            _c("i", { staticClass: "menu-icon iconfont mb-icon-common-index" }),
            _c("span", { staticClass: "menu-name" }, [_vm._v("首页")])
          ]
        ),
        _c("el-divider", { staticClass: "less-divide" }),
        _c("App-List")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/logo-black.png"), alt: "BYGO" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }