var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.regionList.length > 1 && !_vm.readonly
        ? _c(
            "el-dropdown",
            {
              staticClass: "region-select-container",
              attrs: { placement: "bottom-start" }
            },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v("\n      " + _vm._s(_vm.regionInfo.name)),
                _c("i", {
                  staticClass: "iconfont mb-icon-common-switch1 el-icon--right"
                })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "region-list",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                _vm._l(_vm.regionList, function(region) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: region.code,
                      class: { active: _vm.currentRegion == region.code },
                      nativeOn: {
                        click: function($event) {
                          return _vm.changeRegion(region)
                        }
                      }
                    },
                    [_vm._v("\n          " + _vm._s(region.name) + "\n      ")]
                  )
                }),
                1
              )
            ],
            1
          )
        : _c("span", { staticClass: "el-dropdown-link" }, [
            _vm._v(_vm._s(_vm.regionInfo.name))
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }