var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "err-page" }, [
    _c("img", { attrs: { src: require("@/assets/img/404.png") } }),
    _c("div", { staticClass: "prompt" }, [
      _vm._v("sorry,您访问的页面在路上迷失了...")
    ]),
    _c("div", { staticClass: "go-home", on: { click: _vm.goHome } }, [
      _vm._v("返回首页")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }