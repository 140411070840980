var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-item",
      on: {
        mouseenter: function($event) {
          _vm.data.hover = true
        },
        mouseleave: function($event) {
          _vm.data.hover = false
        },
        click: function($event) {
          return _vm.rowClick($event)
        }
      }
    },
    [
      _c("div", { staticClass: "top" }, [
        _vm.data.isRead == 0 ? _c("div", { staticClass: "new" }) : _vm._e(),
        _c("div", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm.data.createTime))
        ])
      ]),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("el-checkbox", {
            on: { change: _vm.itemClick },
            model: {
              value: _vm.data.checked,
              callback: function($$v) {
                _vm.$set(_vm.data, "checked", $$v)
              },
              expression: "data.checked"
            }
          }),
          _c(
            "el-tooltip",
            {
              staticClass: "icon-item",
              attrs: {
                "popper-class": "msg-content",
                effect: "dark",
                content: _vm.data.content,
                placement: "bottom"
              }
            },
            [
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.data.content))
              ])
            ]
          ),
          _vm.data.hover
            ? _c("div", { staticClass: "message-operation" }, [
                _vm.data.isRead == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "item-btn",
                        on: {
                          click: function($event) {
                            return _vm.read()
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-edit" }),
                        _c("span", { staticClass: "item-btn-label" }, [
                          _vm._v("标记为已读")
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "item-btn",
                    on: {
                      click: function($event) {
                        return _vm.del()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "iconfont mb-icon-common-delete" }),
                    _c("span", { staticClass: "item-btn-label" }, [
                      _vm._v("删除")
                    ])
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }