<template>
  <div>
    <el-popover placement="bottom-end" width="180" popper-class="iopen-user-popover" :visible-arrow="false" trigger="hover">
      <div class="user-menu">
        <div class="item" @click="$_goto('/personal')">
          <i class="iconfont mb-icon-common-personal"></i> 
          <span>个人中心</span>
        </div>
        <div v-if="!!isSuper" class="item" @click="$_goto('/company/version')">
          <i class="iconfont mb-icon-common-company"></i> 
          <span>企业管理</span>
        </div>
        <div class="item" @click="wxOAOpen">
          <i class="iconfont mb-icon-mobile"></i> 
          <span>移动端</span>
          <span class="new">new</span>
        </div>
        <div class="split-line"></div>
        <div class="item" @click="exit">
          <i class="iconfont mb-icon-common-exit"></i> 
          <span>退出登录</span>
        </div>
      </div>
      <div class="user-avatar" slot="reference">
        <el-avatar :size="26" :src="circleUrl"></el-avatar>
      </div>
    </el-popover>
    <WXOfficialAccounts ref="wxOA"></WXOfficialAccounts>
  </div>
</template>
<script>
import WXOfficialAccounts from '@/components/WX/WXOfficialAccounts'
import EmptyImg from '@/assets/img/user-head-large.png'

import { domainHost } from '@/assets/script/host.js'
import siteConfig from '@/config/site.config'

export default {
  components: {
    WXOfficialAccounts
  },
  data() {
    return {
      isSuper: 0,
      popOffset: -12,
      realName: "",
      circleUrl: EmptyImg
    };
  },
  watch: {
    "$store.getters.headImgUrl": function (key) {
      this.circleUrl = key;
      // this.circleUrl = `${this.$URL.common.file.download}/${key}`
    },
    "$store.getters.realName": function (key) {
      this.realName = key;
    },
  },

  created() {
    this.isSuper = this.$store.getters.isSuper

    let key = this.$store.getters.headImgUrl;
    this.realName = this.$store.getters.realName;
    if (key) {
      this.circleUrl = key;
      // this.circleUrl = `${this.$URL.common.file.download}/${key}`
    }
  },
  methods: {
    async exit() {
      await this.$store.dispatch("user/logout");

      let url = location.protocol + '//' + domainHost
      location.href = url + siteConfig.loginUrl
    },
    wxOAOpen() {
      this.$refs.wxOA.init()
    }
  }
}
</script>

<style scoped>
.split-line{
  height: 1px;
  margin: 5px 0;
  border-bottom: 1px solid #e6eaea;
}
</style>
<style>
.iopen-user-popover {
  box-shadow: 0px 0px 4px 0px rgba(184,184,184,0.50) !important;
  box-sizing: border-box;
  padding: 0 !important;
}
.iopen-user-popover.el-popover{
	border-radius: 4px;
  border: none;
}
.user-menu {
  width: 180px;
  padding: 10px 0;
  overflow: hidden;
}
.user-menu > .item {
  width: 180px;
  box-sizing: border-box;
  cursor: pointer;
  height: 36px;
  line-height: 36px;
  text-decoration: none;
  padding: 0 20px;
  color: #798A89;
}
.user-menu > .item > i {
  margin-right: 10px;
}
.user-menu > .item:active,
.user-menu > .item:hover {
  background-color: #e8f8f3;
}
.user-menu > .item .new{
  display: inline-block;
  background: #F56C6C;
  color: #fff;
  border-radius: 2px;
  height: 16px;
  line-height: 16px;
  margin-left: 10px;
  padding: 0 5px;
}
.user-avatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 1px;
  border: 1px solid #00bd89;
}

.user-info .el-avatar>img,
.user-avatar .el-avatar>img{
  width: 100%;
  vertical-align: middle;
}

</style>
