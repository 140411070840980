var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aside-s" },
    [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "light",
                content: "返回首页",
                placement: "bottom"
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/logo-white.png"),
                  alt: "BYGO"
                },
                on: { click: _vm.goHome }
              })
            ]
          )
        ],
        1
      ),
      _vm._l(_vm.menus, function(m, mI) {
        return _c(
          "div",
          { key: mI, staticClass: "menu" },
          [
            _c("div", { staticClass: "menu-title" }, [_vm._v(_vm._s(m.title))]),
            _vm._l(m.children, function(c, cI) {
              return _c("section", { key: cI }, [
                cI < 5
                  ? _c(
                      "div",
                      {
                        staticClass: "menu-item",
                        class: { active: c.active },
                        on: {
                          click: function($event) {
                            return _vm.$_goto(c.url)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "menu-icon", class: c.icon }),
                        _c("span", { staticClass: "menu-name" }, [
                          _vm._v(_vm._s(c.name))
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }