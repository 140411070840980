let hostmap = {
  dev: '/devProxy',
  sit: 'http://172.30.40.39:9900',
  //sit: 'http://39.96.6.104:11099', // 外网
  uat: 'http://webuat.huishenbogu.cn:82',
  prod: 'https://bygocloud.cn'
}

export const domainMap = {
  dev: 'localhost.com:8080',
  sit: '172.30.40.39:81',
  //sit: '39.96.6.104:11081',  // 外网
  uat: 'webuat.huishenbogu.cn:82',
  prod: 'bygocloud.cn'
}
let domainRootMap = {
  dev: 'localhost.com',
  sit: '172.30.40.39',
  //sit: '39.96.6.104',  // 外网
  uat: 'webuat.huishenbogu.cn',
  prod: 'bygocloud.cn'
}

let wxcbhostmap = {
  dev: 'https://upm.ngrok2.xiaomiqiu.cn',
  sit: 'https://upm.ngrok2.xiaomiqiu.cn',
  uat: 'http://webuat.huishenbogu.cn:82',
  prod: 'https://bygocloud.cn'
}

let hostassetsmap = {
  dev: 'http://172.30.40.39:81',
  sit: 'http://172.30.40.39:81',
  //sit: 'http://39.96.6.104:11081',  // 外网
  uat: 'http://webuat.huishenbogu.cn:82',
  prod: 'https://static.bygocloud.cn'
}

let sentryhostmap = {
  dev: 'http://e7a184136d174016b90e1268827755fe@172.30.40.99:9000/8',
  sit: 'http://e7a184136d174016b90e1268827755fe@172.30.40.99:9000/8',
  uat: 'http://cf67a1a5ff1d4b189d19a83eea29b306@sentry.huishenbogu.cn:83/21',
  prod: 'https://e947661021e64503a82a6e736bf31884@sentry.bygocloud.cn/22'
}

// 服务器host
let HUrl = hostmap[process.env.NODE_ENV]
let domain = localStorage.getItem('login-domain')
domain = domain ? JSON.parse(domain) : ''
// login页面不需要处理二级域名
let isLogin = location.hash.indexOf('/login') > -1
if(domain && domain.domainStatus === 1 && !isLogin && (process.env.NODE_ENV == 'prod' || process.env.NODE_ENV == 'uat')) {
  let hArr = HUrl.split('//')
  HUrl = hArr[0] + '//' + domain.domain + '.' + hArr[1]
}
const host = HUrl
// const host = hostmap[process.env.NODE_ENV]

export default host
// 微信回调host
export const WXCbHost = wxcbhostmap[process.env.NODE_ENV]
// 微信静态资源地址
export const WXAssetsHost = hostassetsmap[process.env.NODE_ENV]
// sentry地址
export const SentryHost = sentryhostmap[process.env.NODE_ENV]
// 域名地址
export const domainHost = domainMap[process.env.NODE_ENV]
// 域名地址（不带端口）
export const domainRootHost = domainRootMap[process.env.NODE_ENV]
