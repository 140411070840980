import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=53ba8ce7&scoped=true&"
import script from "./Drawer.vue?vue&type=script&lang=js&"
export * from "./Drawer.vue?vue&type=script&lang=js&"
import style0 from "./Drawer.vue?vue&type=style&index=0&id=53ba8ce7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ba8ce7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\we-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53ba8ce7')) {
      api.createRecord('53ba8ce7', component.options)
    } else {
      api.reload('53ba8ce7', component.options)
    }
    module.hot.accept("./Drawer.vue?vue&type=template&id=53ba8ce7&scoped=true&", function () {
      api.rerender('53ba8ce7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Message/Detail/Drawer.vue"
export default component.exports