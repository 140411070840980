var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        staticClass: "search-input",
        attrs: {
          "prefix-icon": "el-icon-search",
          placeholder: "搜索应用名称",
          clearable: ""
        },
        model: {
          value: _vm.searchAppName,
          callback: function($$v) {
            _vm.searchAppName = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "searchAppName"
        }
      }),
      !_vm.hasIndustry
        ? _c(
            "ul",
            {
              staticClass: "app-container",
              staticStyle: { "margin-top": "20px" }
            },
            _vm._l(_vm.menus[0].list, function(app) {
              return _c(
                "li",
                {
                  key: app.id,
                  staticClass: "app-item",
                  class: { active: app.isActive },
                  on: {
                    click: function($event) {
                      return _vm.menus[0].click(app)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "icon" }, [
                    _c("i", {
                      staticClass: "iconfont",
                      class: app.$_style.icon,
                      style: app.$_style
                    })
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(app.name))
                  ])
                ]
              )
            }),
            0
          )
        : _c(
            "el-tabs",
            {
              staticClass: "app-tabs",
              model: {
                value: _vm.curId,
                callback: function($$v) {
                  _vm.curId = $$v
                },
                expression: "curId"
              }
            },
            _vm._l(_vm.menus, function(m) {
              return _c(
                "el-tab-pane",
                { key: m.id, attrs: { label: m.name, name: m.id } },
                [
                  _c(
                    "ul",
                    { staticClass: "app-container" },
                    _vm._l(m.list, function(app) {
                      return _c(
                        "li",
                        {
                          key: app.id,
                          staticClass: "app-item",
                          class: { active: app.isActive },
                          on: {
                            click: function($event) {
                              return m.click(app)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "icon" }, [
                            _c("i", {
                              staticClass: "iconfont",
                              class: app.$_style.icon,
                              style: app.$_style
                            })
                          ]),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(app.name))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }