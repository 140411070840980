var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c("el-aside", { attrs: { width: "280px" } }, [_c("my-aside")], 1),
      _c(
        "el-container",
        [
          _c("el-header", { staticClass: "my-header" }, [_c("my-header")], 1),
          _c("el-main", { staticClass: "my-main" }, [_c("router-view")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }