var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.container
    ? _c(
        "div",
        [
          _c(_vm.container, {
            tag: "component",
            attrs: { viewModel: _vm.viewModel, data: _vm.data }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }