var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "append-to-body": "",
        width: "800px",
        "custom-class": "wx-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("div", { staticClass: "wx-dialog-title" }, [
                _c("i", { staticClass: "iconfont mb-icon-wxicon" }),
                _c("span", [_vm._v("微信服务号")])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "wx-oa-panel" }, [
        _c("div", { staticClass: "wx-info" }, [
          _c("img", { attrs: { src: _vm.wxUrl } }),
          _c("div", { staticClass: "desc" }, [
            _vm._v("扫码关注「BYGO」微信公众号")
          ]),
          _c("div", { staticClass: "desc" }, [_vm._v("在微信上使用 BYGO")])
        ]),
        _c("div", { staticClass: "func-box" }, [
          _c("div", { staticClass: "func-item" }, [
            _c("i", { staticClass: "iconfont mb-icon-wxwork" }),
            _c("span", { staticClass: "label" }, [_vm._v("微信办公")])
          ]),
          _c("div", { staticClass: "func-item" }, [
            _c("i", { staticClass: "iconfont mb-icon-custome-service" }),
            _c("span", { staticClass: "label" }, [_vm._v("客服支持")])
          ]),
          _c("div", { staticClass: "func-item" }, [
            _c("i", { staticClass: "iconfont mb-icon-wxinformation" }),
            _c("span", { staticClass: "label" }, [_vm._v("获取最新资讯")])
          ])
        ]),
        _c("div", { staticClass: "center-line" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }